import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-426b748c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardGrid = _resolveComponent("DashboardGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardRefundOrdersPartial',
      exportUrl: 'form/grid/ExportRefundsCsv',
      exportName: 'RefundOrders'
    }),
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardRefundOrdersPercentageRefundTabPartial',
      exportUrl: 'form/grid/ExportRefundsCsv',
      exportName: 'RefundOrders'
    }),
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardRefundsValuePartial',
      exportUrl: 'form/grid/ExportRefundsCsv',
      exportName: 'RefundOrders'
    }),
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardRefundsValuePercentagePartial',
      exportUrl: 'form/grid/ExportRefundsCsv',
      exportName: 'RefundOrders'
    })
  ]))
}